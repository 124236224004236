import React from "react";

export default function Dataiku() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-30">What makes us different?</h2>
                <p className="margin-0 pb-40">
                  We provide the best Dataiku guidance to our clients. Dataiku
                  implementation and execution is made easy with SuchiERP.{" "}
                </p>
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Our professional strategists build insightful guide to
                      train your team for various Dataiku models.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      We deliver 24*7 support and ensure that your Dataiku is
                      always running smoothly. Our administration techniques are
                      highly-effective.{" "}
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      We employ various AI tools to deliver the performance and
                      service our clients desire. Our useful AI strategies keep
                      your machine learning road map running.{" "}
                    </span>
                  </li>
                </ul>
                <p className="margin-0 pb-40">
                  For more information and assistance, you can contact SuchiERP.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img src="assets/images/pages/dataiku/1.png" alt="Images" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section End */}
      <div className="rs-services style3 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">Dataiku assistance at SuchiERP</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 md-mb-50">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/4.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Dataiku For Business Connoisseur</a>
                  </h2>
                  <p className="services-txt">
                    Dataiku promotes collaboration run AI initiatives where tech
                    and business work hand-in-hand better. It fashions a single
                    place for conversation and data assignments within any team.
                    Dataiku harmonizes with other collaborating podiums such as
                    Microsoft Teams, Slack, Atlassian and Confluence.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/2.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Analytical Apps with Dataiku </a>
                  </h2>
                  <p className="services-txt">
                    Dataiku impedes the quick creation of interactive visual
                    front ends for rational outcomes. It organizes analytical
                    dashboards and applications for business punters.
                  </p>
                  <ul className="icon-item mt-20">
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Business Dashboards- Dataiku makes it trouble-free to
                        craft out project dashboards and share them with
                        business users. Triggering updates and scheduling
                        updates for dashboards are not so difficult and make
                        sure that the most up-to-date information is available.
                        With the facility of dashboards as part of Dataiku
                        assignment project stakeholders can simply track the
                        KPIs and see AI projects and values.
                      </span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        For business punters to engross themselves with AI
                        forecast- the results of AI assignments need a boundary.
                        With Dataiku Apps business analysts and data scientists
                        can simply produce apps and publish a project with a few
                        clicks. The profit is that more and more people
                        throughout an organization can make use of advanced
                        predictive insights and Artificial Intelligence can
                        provide the utmost value to the business.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
      <div className="rs-services style3 pt-100 pb-10">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">
              Mechanize Analytics for a Self-Serve Business
            </h2>
            <p className="services-txt mt-30 mb-30">
              Dataiku rapidly automates business progression by faultless
              scheduling processes. It empowers the business to automate
              analytical tasks that are mostly recurring, without relying on IT
              teams or data science. This helps people to concentrate on more
              impactful and fertile data assignments.
            </p>
          </div>
        </div>
      </div>

      <div className="rs-services style3 pt-20 pb-10">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">Web Apps and More</h2>
            <p className="services-txt mt-30 mb-30">
              With numerous ways to develop applications, organizations can
              construct applications with the insights predicted by them to
              drive good customer experiences and furthermore improve their
              proficiency. JavaScript, R Shiny, Bokeh, Dash and many more are
              just to name a few among those leading frameworks that Dataiku
              supports.
            </p>
          </div>
        </div>
      </div>

      <div className="rs-services style3 pt-20 pb-10">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">Dataiku assistance at SuchiERP Inc.</h2>
            <p className="services-txt mt-30 mb-30">
              No matter if you already employ Dataiku or are about to create
              your firm’s first Dataiku concept, we understand your
              requirements. SuchiERP is well-versed with its customer base. Our
              trained machine learning experts assist you at every step while
              also creating a perfect strategy.
            </p>
          </div>
        </div>
      </div>

      {/* Partner Section Start */}
      <div className="rs-patter-section black-bg pt-80 pb-75">
        <div className="container custom">
          <div
            className="rs-carousel owl-carousel"
            data-loop="true"
            data-items={5}
            data-margin={30}
            data-autoplay="true"
            data-hoverpause="true"
            data-autoplay-timeout={5000}
            data-smart-speed={800}
            data-dots="false"
            data-nav="false"
            data-nav-speed="false"
            data-md-device={5}
            data-md-device-nav="false"
            data-md-device-dots="false"
            data-center-mode="false"
            data-ipad-device2={4}
            data-ipad-device-nav2="false"
            data-ipad-device-dots2="false"
            data-ipad-device={4}
            data-ipad-device-nav="false"
            data-ipad-device-dots="false"
            data-mobile-device={2}
            data-mobile-device-nav="false"
            data-mobile-device-dots="false"
          >
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/1.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/1.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/2.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/2.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/3.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/3.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/4.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/4.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/5.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/5.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Partner Section End */}
    </div>
  );
}
