import React from "react";
import { Link } from "react-router-dom";
import Router from "../../routes";
import Img9 from "../../../assets/images/9.png";
import Img10 from "../../../assets/images/10.png";
import Img22 from "../../../assets/images/22.png";
import Img23 from "../../../assets/images/23.png";

const Header = () => {
  return (
    <>
      {/*Full width header Start*/}
      <div className="full-width-header">
        {/*Header Start*/}
        <header id="rs-header" className="rs-header style2 header-transparent">
          {/* Topbar Area Start */}
          <div className="topbar-area style1">
            <div className="container custom">
              <div className="row y-middle">
                <div className="col-lg-7">
                  <div className="topbar-contact">
                    <ul>
                      <li>
                        <i className="flaticon-email" />
                        <a href="mailto:info@suchierp.com">info@suchierp.com</a>
                      </li>
                      <li>
                        <i className="flaticon-location" />
                        6701 Highway Blvd #211, Katy, Texas 77494
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 text-right">
                  <div className="toolbar-sl-share">
                    <ul>
                      <li className="opening">
                        {" "}
                        <em>
                          <i className="flaticon-clock" />
                          Monday - Friday / 9AM - 5PM
                        </em>{" "}
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/profile.php?id=100071401861585"
                          target={"_blank"}
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/in/suchi-erp-inc-erp-5744231bb"
                          target={"_blank"}
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/ErpSuchi"
                          target={"_blank"}
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/erpsuchi.neet?utm_source=qr"
                          target={"_blank"}
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Topbar Area End */}

          {/* Menu Start */}
          <div className="menu-area menu-sticky">
            <div className="container custom">
              <div className="row-table">
                <div className="col-cell header-logo">
                  <div className="logo-area">
                    <a href="/">
                      <img src="assets/images/brand-logo.png" alt="logo" />
                    </a>
                  </div>
                </div>
                <div className="col-cell">
                  <div className="rs-menu-area">
                    <div className="main-menu">
                      <nav className="rs-menu hidden-md">
                        <ul className="nav-menu">
                          <li className="menu-item-has-children">
                            <a href="/">Products</a>
                            <ul className="sub-menu">
                              <div className="row d-flex align-items-center justify-content-center">
                                <div className="col-md-4 border-right">
                                  <li>
                                    <Link to="/FieldServiceManagementSoftware">
                                      Field Service Management Software
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/Logistics">Logistics</Link>
                                  </li>
                                  <li>
                                    <Link to="/Overview">Overview</Link>
                                  </li>
                                  <li>
                                    <Link to="/ProjectAccounting">
                                      Project Accounting
                                    </Link>
                                  </li>
                                </div>
                                <div className="col-md-4 border-right">
                                  <li>
                                    <Link to="/Payroll">Payroll</Link>
                                  </li>
                                  {/* <li><Link to="/ProductDashboard">Product Dashboard</Link></li> */}
                                  <li>
                                    <Link to="/PointOfSale">Point Of Sale</Link>
                                  </li>
                                </div>
                                <div className="col-md-4 border-right pl-10 pr-10 ">
                                  <img src={Img9} alt="" />
                                </div>
                              </div>
                            </ul>
                          </li>
                          <li className="menu-item-has-children">
                            <a href="/">Productivity Software</a>
                            <ul className="sub-menu">
                              <div className="row d-flex">
                                <div className="col-md-6 border-right">
                                  <div className="d-flex flex-column">
                                    <div className="">
                                      <li>
                                        <Link to="">
                                          <h4>
                                            <b>Cloud Tech and Tools</b>
                                          </h4>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/aws">AWS</Link>
                                      </li>
                                      <li>
                                        <Link to="/microsoft-azure">
                                          Microsoft Azure
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/cloud-foundation">
                                          Cloud Foundation
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="managed-mlops">
                                          Cloud DataOps
                                        </Link>
                                      </li>
                                    </div>
                                    <div className="border-top">
                                      <li>
                                        <Link to="">
                                          <h4>
                                            <b>Technologies</b>
                                          </h4>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/dataiku">Dataiku</Link>
                                      </li>
                                      <li>
                                        <Link to="/snowflake">Snowflake</Link>
                                      </li>
                                      <li>
                                        <Link to="/bigdata">Big Data</Link>
                                      </li>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <li>
                                    <Link to="">
                                      <h4>
                                        <b>Artificial Intelligence and Data</b>
                                      </h4>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/machine-learning">
                                      Machine Learning
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="ai-strategy">AI Strategy</Link>
                                  </li>
                                  <li>
                                    <Link to="data-strategy">
                                      Data Strategy
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="data-science">Data Science</Link>
                                  </li>
                                  <li>
                                    <Link to="data-engineering">
                                      Data Engineering
                                    </Link>
                                  </li>

                                  <img src={Img23} className="pt-20" alt="" />
                                </div>
                              </div>
                            </ul>
                          </li>

                          <li className="menu-item-has-children">
                            <a href="/">Specialization</a>
                            <ul className="sub-menu">
                              <div className="row d-flex align-items-center justify-content-center">
                                <div className="col-md-4 border-right">
                                  {/* <li>
                                    <Link to="/CRM">
                                      Customer Relationship Management (CRM)
                                    </Link>
                                  </li> */}
                                  {/* <li>
                                    <Link to="/ConstructionManagement">
                                      Construction Management
                                    </Link>
                                  </li> */}
                                </div>
                                <div className="col-md-4 border-right">
                                  <li>
                                    <Link to="/FinancialManagement">
                                      Financial Management
                                    </Link>
                                  </li>
                                  {/* <li><Link to="/OrderManagement">Order Management</Link></li> */}
                                  <li>
                                    <Link to="/ServiceManagement">
                                      Service Management
                                    </Link>
                                  </li>
                                </div>
                                <div className="col-md-4 border-right pl-10 pr-10 ">
                                  <img src={Img22} alt="" />
                                </div>
                              </div>
                            </ul>
                          </li>

                          <li className="menu-item-has-children">
                            <Link to="careers">Careers</Link>

                            <ul className="sub-menu">
                              <div className="row d-flex align-items-center justify-content-center">
                                <div className="col-md-4 border-right">
                                  <li>
                                    <Link to="careers">Careers</Link>
                                  </li>
                                  <li>
                                    <Link to="ebooks">eBooks</Link>
                                  </li>
                                  <li>
                                    <Link to="videos">Videos</Link>
                                  </li>
                                </div>
                                <div className="col-md-8 d-flex justify-content-center">
                                  <img
                                    src={Img10}
                                    style={{ width: "50%" }}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </ul>
                          </li>
                          <li className>
                            <Link to="/contact-us">Approach</Link>
                          </li>
                        </ul>{" "}
                        {/* //.nav-menu */}
                      </nav>
                    </div>{" "}
                    {/* //.main-menu */}
                  </div>
                </div>

                <div className="col-cell">
                  <div class="btn-part">
                    <Link
                      to="/ScheduleDemo"
                      class="readon consultant paste-btn more"
                    >
                      Schedule a Demo
                    </Link>
                  </div>
                </div>

                <div className="col-cell hidden-lg hidden-md hidden-sm visible-xs">
                  <div className="expand-btn-inner">
                    <ul>
                      <li className="humburger">
                        <a
                          id="nav-expander"
                          className="nav-expander bar"
                          href="/"
                        >
                          <div className="bar">
                            <span className="dot1" />
                            <span className="dot2" />
                            <span className="dot3" />
                            <span className="dot4" />
                            <span className="dot5" />
                            <span className="dot6" />
                            <span className="dot7" />
                            <span className="dot8" />
                            <span className="dot9" />
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Menu End */}

          {/* Canvas Menu start */}
          <nav className="right_menu_togle hidden-md">
            <div className="close-btn">
              <a id="nav-close" className="nav-close" href="/">
                <div className="line">
                  <span className="line1" />
                  <span className="line2" />
                </div>
              </a>
            </div>
            <div className="canvas-logo">
              <a href="/">
                <img src="assets/images/brand-logo.png" alt="logo" />
              </a>
            </div>
            <div className="offcanvas-text">
              <p>
                SuchiERP is a leading IT solutions company that excels in
                delivering AI and ML services. With an experience of #years, we
                aim to deliver prime solutions to our clients.{" "}
              </p>
            </div>
            <div className="media-img">
              <img src="assets/images/pages/ai/3.gif" alt="Images" />
            </div>
            <div className="canvas-contact">
              <div className="address-area">
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-location" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Address</h4>
                    <em>6701 Highway Blvd #211, Katy, Texas 77494</em>
                  </div>
                </div>
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-email" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Email</h4>
                    <em>
                      <a href="mailto:info@suchierp.com">info@suchierp.com</a>
                    </em>
                  </div>
                </div>
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-call" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Phone</h4>
                    <em>(+1) 832-955-1312</em>
                  </div>
                </div>
              </div>
              <ul className="social">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100071401861585"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/suchi-erp-inc-erp-5744231bb"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/ErpSuchi"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/erpsuchi.neet?utm_source=qr"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          {/* Canvas Menu end */}

          {/* Canvas Mobile Menu start */}
          <nav
            className="right_menu_togle mobile-navbar-menu"
            id="mobile-navbar-menu"
          >
            <div className="close-btn">
              <a id="nav-close2" className="nav-close" href="/">
                <div className="line">
                  <span className="line1" />
                  <span className="line2" />
                </div>
              </a>
            </div>
            <ul className="nav-menu">
              <li className="menu-item-has-children">
                <a href="/">Products</a>
                <ul className="sub-menu">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-4 border-right">
                      <li>
                        <Link to="">Field Service Management Software</Link>
                      </li>
                      <li>
                        <Link to="/Logistics">Logistics</Link>
                      </li>
                      <li>
                        <Link to="/Overview">Overview</Link>
                      </li>
                      <li>
                        <Link to="/ProjectAccounting">Project Accounting</Link>
                      </li>
                    </div>
                    <div className="col-md-4 border-right">
                      <li>
                        <Link to="/Payroll">Payroll</Link>
                      </li>
                      {/* <li><Link to="/ProductDashboard">Product Dashboard</Link></li> */}
                      <li>
                        <Link to="/PointOfSale">Point Of Sale</Link>
                      </li>
                    </div>
                    <div className="col-md-4 border-right pl-10 pr-10 ">
                      <img src={Img9} alt="" />
                    </div>
                  </div>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <a href="/">Productivity Software</a>
                <ul className="sub-menu">
                  <div className="row d-flex">
                    <div className="col-md-6 border-right">
                      <div className="d-flex flex-column">
                        <div className="">
                          <li>
                            <Link to="">
                              <h4>
                                <b>Cloud Tech and Tools</b>
                              </h4>
                            </Link>
                          </li>
                          <li>
                            <Link to="/aws">AWS</Link>
                          </li>
                          <li>
                            <Link to="/microsoft-azure">Microsoft Azure</Link>
                          </li>
                          <li>
                            <Link to="/cloud-foundation">Cloud Foundation</Link>
                          </li>
                          <li>
                            <Link to="managed-mlops">Cloud DataOps</Link>
                          </li>
                        </div>
                        <div className="border-top">
                          <li>
                            <Link to="">
                              <h4>
                                <b>Technologies</b>
                              </h4>
                            </Link>
                          </li>
                          <li>
                            <Link to="/dataiku">Dataiku</Link>
                          </li>
                          <li>
                            <Link to="/snowflake">Snowflake</Link>
                          </li>
                          <li>
                            <Link to="/bigdata">Big Data</Link>
                          </li>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 border-right">
                      <li>
                        <Link to="">
                          <h4>
                            <b>Artificial Intelligence and Data</b>
                          </h4>
                        </Link>
                      </li>
                      <li>
                        <Link to="/machine-learning">Machine Learning</Link>
                      </li>
                      <li>
                        <Link to="ai-strategy">AI Strategy</Link>
                      </li>
                      <li>
                        <Link to="data-strategy">Data Strategy</Link>
                      </li>
                      <li>
                        <Link to="data-science">Data Science</Link>
                      </li>
                      <li>
                        <Link to="data-engineering">Data Engineering</Link>
                      </li>

                      <img src={Img23} className="pt-20" alt="" />
                    </div>
                  </div>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <a href="/">Specialization</a>
                <ul className="sub-menu">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-4 border-right">
                      {/* <li>
                        <Link to="/CRM">
                          Customer Relationship Management (CRM)
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to="/ConstructionManagement">
                          Construction Management
                        </Link>
                      </li> */}
                    </div>
                    <div className="col-md-4 border-right">
                      <li>
                        <Link to="/FinancialManagement">
                          Financial Management
                        </Link>
                      </li>
                      {/* <li><Link to="/OrderManagement">Order Management</Link></li> */}
                      <li>
                        <Link to="/ServiceManagement">Service Management</Link>
                      </li>
                    </div>
                    <div className="col-md-4 border-right pl-10 pr-10 ">
                      <img src={Img22} alt="" />
                    </div>
                  </div>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link to="careers">Careers</Link>

                <ul className="sub-menu">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-4 border-right">
                      <li>
                        <Link to="careers">Careers</Link>
                      </li>
                      <li>
                        <Link to="ebooks">eBooks</Link>
                      </li>
                      <li>
                        <Link to="videos">Videos</Link>
                      </li>
                    </div>
                    <div className="col-md-8 d-flex justify-content-center">
                      <img src={Img10} style={{ width: "50%" }} alt="" />
                    </div>
                  </div>
                </ul>
              </li>
              <li className>
                <Link to="/contact-us">Approach</Link>
              </li>
            </ul>{" "}
            {/* //.nav-menu */}
            <div className="canvas-contact">
              <div className="address-area">
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-location" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Address</h4>
                    <em>6701 Highway Blvd #211, Katy, Texas 77494</em>
                  </div>
                </div>
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-email" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Email</h4>
                    <em>
                      <a href="mailto:info@suchierp.com">info@suchierp.com</a>
                    </em>
                  </div>
                </div>
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-call" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Phone</h4>
                    <em>(+1) 832-955-1312</em>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          {/* Canvas Menu end */}
        </header>
        {/*Header End*/}
      </div>
      {/*Full width header End*/}

      <Router />
    </>
  );
};

export default Header;
