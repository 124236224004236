import React from "react";
import FSMProduct from "../../../assets/images/fsm-product.png";
import FSMLogo from "../../../assets/images/fsm-logo.png";

export default function FieldServiceManagementSoftware() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-0 pb-20 md-pt-00 md-pb-20"
      >
        <div className="container">
          <div className="row y-middle mt-50">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <div className="d-flex justify-content-between">
                  <img src={FSMProduct} alt="Images" className="pe-5 me-5" />
                  {/* <img src={FSMLogo} alt="Images" /> */}
                </div>

                <p className="mt-0">
                  FSM Grid by FSM Global, recognized by Gartner, is a SaaS-based
                  field service management software that revolutionizes field
                  service operations across diverse industries. With more than
                  20 years of experience, FSM Global has engineered FSM Grid to
                  optimize workflows, enhance customer satisfaction, and drive
                  operational efficiency. FSM Grid offers real-time scheduling,
                  advanced analytics, seamless communication, and mobile
                  accessibility, empowering businesses from different verticals
                  to transform their service delivery. By integrating FSM Grid,
                  organizations can expect reduced costs, improved response
                  times, and a significant boost in overall productivity, making
                  it an indispensable tool for any industry looking to elevate
                  their service standards.
                </p>
              </div>
            </div>
          </div>
          <div className="row y-middle mt-50">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title">Exquisite Features</h2>
                <ul className="icon-item mt-30 row">
                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Scheduling & Dispatch Board
                    </span>
                  </li>
                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Work Order Management </span>
                  </li>
                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Multi-Vendor Management</span>
                  </li>
                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Contract & SLA Management</span>
                  </li>

                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Location Management</span>
                  </li>

                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Quotation Management</span>
                  </li>

                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      AI-Driven Predictive Maintenance
                    </span>
                  </li>

                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Back office Integration</span>
                  </li>

                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">IoT Integration</span>
                  </li>

                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Customer Portal</span>
                  </li>

                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Dynamic Forms</span>
                  </li>

                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Spare Parts Inventory Management
                    </span>
                  </li>

                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Reports & Dashboards</span>
                  </li>

                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Preventive Maintenance</span>
                  </li>

                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Mobile App</span>
                  </li>

                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Billing & Invoice</span>
                  </li>

                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Workflow Management</span>
                  </li>

                  <li className="col-12 col-sm-4">
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Email & SMS Notifications</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="rs-services style3 pt-0 pb-0 md-pt-0 md-pb-0">
        <div className="container">
          <img src={Img28} alt="" />
        </div>
      </div> */}
    </div>
  );
}
