import React from "react";

export default function DataStrategy() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-30">The importance of data strategy</h2>
                <p className="margin-0 pb-40">
                  Virtually every business collects data in multiple forms, and
                  a data strategy enables a business to manage and interpret all
                  of that data. It also puts a business in a strong position to
                  solve challenges such as:
                </p>
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Lack of deep understanding of critical parts of the
                      business (customers, supply chain, competitive landscape,
                      etc.) and the processes that make them tick.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Data privacy, data integrity, and data quality issues that
                      undercut your ability to analyze data.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      A lack of clarity about current business needs (a problem
                      that descriptive analytics can help solve) and goals.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Inefficient movement of data between different parts of
                      the business, or duplication of data by multiple business
                      units.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Slow and inefficient business processes
                    </span>
                  </li>
                </ul>
                <div className="btn-part mt-42">
                  <a
                    className="readon consultant paste-btn more"
                    href="https://www.udemy.com/course/the-data-strategy-course-building-a-data-driven-business/"
                  >
                    Know More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/pages/data-strategy/1.png"
                  alt="Images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section End */}
      <div className="rs-services style3 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">4 components of Data strategy</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 md-mb-50">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/4.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Business strategy</a>
                  </h2>
                  <p className="services-txt">
                    {" "}
                    Your data strategy should reinforce and advance your overall
                    business strategy, which refers to the processes you use to
                    operate and improve your business.
                  </p>
                  <p className="services-txt">
                    {" "}
                    To that end, establish clear goals and measurable objectives
                    for your data strategy that serve your larger business
                    strategy. For example, your data strategy could include a
                    goal of keeping data storage costs below a certain
                    threshold. To achieve this goal, the strategy might define
                    storage tools or services that meet your cost requirements,
                    as well as best practices that can help users optimize
                    storage costs. And it should establish metrics, such as
                    average cost per gigabyte of storage, to help you track your
                    success in achieving this goal.
                  </p>
                  <p className="services-txt">
                    {" "}
                    Set both long-term and short-term goals. While you might set
                    a short-term goal of performing data quality reviews once
                    every month, for example, a long-term goal might be to
                    achieve continuous data quality, meaning that you identify
                    and address data quality problems continually, rather than
                    relying on periodic checks.
                  </p>
                  <p>
                    Your business already may have a data strategy; however, as
                    the Stitch data strategy guide explains, many companies’
                    data strategies were written years ago when the toolsets and
                    timelines associated with data management and analysis were
                    different. Periodically review your data strategy to assess
                    whether it aligns with your current business goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/2.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Organizational roles</a>
                  </h2>
                  <p className="services-txt">
                    A data strategy should include attention to organizational
                    roles by documenting who does what with the data, in order
                    to facilitate collaboration and avoid duplication. Not
                    everyone in an organization uses data the same way, and
                    their roles in data collection, management, and analytics
                    will vary.{" "}
                  </p>
                  <ul className="icon-item mt-20">
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Data engineers, who oversee the data pipeline and are
                        responsible for building an efficient, reliable data
                        architecture
                      </span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Data scientists, who work with data that the pipeline
                        delivers.
                      </span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Data analysts, who specialize in analyzing and
                        interpreting data.
                      </span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Business managers, who help to manage data operations
                        and review data reports.
                      </span>
                    </li>
                  </ul>
                  <p className="services-txt">
                    When coordinating roles, consider everyone in the
                    organization who uses data in any way, even if working with
                    data is not a primary part of their job responsibilities.
                    For example, an account manager who records customer
                    information has a role to play in data collection, and a
                    sales manager may need data analytics to help plan the next
                    marketing campaign. Your data strategy should document the
                    roles of each team member or group.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/3.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Data architecture</a>
                  </h2>
                  <p className="services-txt">
                    A first step in defining your data architecture is
                    determining what datasets exist among business units across
                    the company. Data catalogs are useful tools for this
                    purpose. If you don’t have a data catalog, review data
                    sources with your team and the users who work with the data.
                  </p>
                  <p className="services-txt">
                    To analyze your data, you need to store it in a single
                    repository, such as a data warehouse or data lake. You may
                    also want to integrate or transform it to put it in a format
                    that lends itself better to analysis.
                  </p>
                  <p className="services-txt">
                    Data identification, ingestion, storage, and analysis are
                    all parts of a data architecture. Documenting and
                    implementing your data architecture is essential for a
                    consistent, predictable data strategy. It also makes it
                    easier to scale your data operations as your needs change.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/1.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Data management</a>
                  </h2>
                  <p className="services-txt">
                    Data management encourages all team members to think of data
                    as a business asset, rather than a byproduct of business
                    operations. It encourages everyone in your organization to
                    follow policies when working with data.{" "}
                  </p>
                  <p className="services-txt">
                    You should update data governance policies as your business
                    needs change. You might store all of your data on-premises
                    today, but if you move your data to the cloud, you may need
                    to update your data governance rules to accommodate
                    cloud-based data management. For example, data that is
                    stored in the cloud might require stricter encryption rules.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
      <div className="rs-services style3 pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">Businesses Develop a Data Strategy to:</h2>
            <ul className="icon-item">
              <li>
                <span className="list-icon">
                  <i className="fa fa-check" />
                </span>
                <span className="list-text">
                  Manage torrents of data that are critical to a company's
                  success.
                </span>
              </li>
              <li>
                <span className="list-icon">
                  <i className="fa fa-check" />
                </span>
                <span className="list-text">
                  Think of the future and trends and how to best manage them.
                </span>
              </li>
              <li>
                <span className="list-icon">
                  <i className="fa fa-check" />
                </span>
                <span className="list-text">
                  Drive innovation and establish a data culture.
                </span>
              </li>
              <li>
                <span className="list-icon">
                  <i className="fa fa-check" />
                </span>
                <span className="list-text">
                  Support the re-imaging of decision making in an organization
                </span>
              </li>
            </ul>
          </div>
          <img
            src="assets/images/pages/data-strategy/2.png"
            className="md-pt-70 md-pb-70"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
