import { Route, Switch } from "react-router-dom";
import React from "react";
import ScrollIntoView from "../../components/ScrollIntoView";

// single pages
import Home from "../Home";
import AboutUs from "../Pages/AboutUs";
import Contactus from "../Pages/Contactus";
import ScheduleDemo from "../Pages/ScheduleDemo";
import MarketPlace from "../Pages/MarketPlace";

//Products tab pages
import Overview from "../Pages/Products/Overview";
import FinancialManagement from "../Pages/Products/FinancialManagement";
import ProjectAccounting from "../Pages/Products/ProjectAccounting";
import CRM from "../Pages/Products/CRM";
import Payroll from "../Pages/Products/Payroll";
import ProductDashboard from "../Pages/Products/ProductDashboard";
import OrderManagement from "../Pages/Products/OrderManagement";
import PointOfSale from "../Pages/Products/PointOfSale";
import ServiceManagement from "../Pages/Products/ServiceManagement";
import ConstructionManagement from "../Pages/Products/ConstructionManagement";
import Logistics from "../Pages/Products/Logistics";
import FieldServiceManagementSoftware from "../Pages/Products/ FieldServiceManagementSoftware";

//Productivity Software tab pages
import BigData from "../Pages/ProductivitySoftware/BigData";
import MachineLearning from "../Pages/ProductivitySoftware/MachineLearning";
import AWS from "../Pages/ProductivitySoftware/Aws";
import Dataiku from "../Pages/ProductivitySoftware/Dataiku";
import Snowflake from "../Pages/ProductivitySoftware/Snowflake";
import MicrosoftAzure from "../Pages/ProductivitySoftware/MicrosoftAzure";
import CloudFoundation from "../Pages/ProductivitySoftware/CloudFoundation";
import AIStrategy from "../Pages/ProductivitySoftware/AIStrategy";
import DataStrategy from "../Pages/ProductivitySoftware/DataStrategy";
import DataScience from "../Pages/ProductivitySoftware/DataScience";
import DataEngineering from "../Pages/ProductivitySoftware/DataEngineering";
import ManagedMLOps from "../Pages/ProductivitySoftware/ManagedMLOps";

// expedient tab pages
import Careers from "../Pages/Careers";
import Ebooks from "../Pages/Ebooks";
import Videos from "../Pages/Videos";
import Blog from "../Pages/Blog";
import FAQ from "../Pages/FAQ";

// other pages
import ComingSoon from "../Pages/ComingSoon";
import StudentRegistration from "../Pages/StudentRegistration";

export function Router() {
  return (
    <ScrollIntoView>
      <Switch>
        <Route exact path={"/"} component={Home} />
        {/* single pages */}
        <Route path="/home" component={Home} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/contact-us" component={Contactus} />
        <Route path="/ScheduleDemo" component={ScheduleDemo} />
        <Route path="/MarketPlace" component={MarketPlace} />

        {/* products pages */}
        <Route path="/Overview" component={Overview} />
        <Route path="/FinancialManagement" component={FinancialManagement} />
        <Route path="/ProjectAccounting" component={ProjectAccounting} />
        <Route path="/CRM" component={CRM} />
        <Route path="/Payroll" component={Payroll} />
        <Route path="/ProductDashboard" component={ProductDashboard} />
        <Route path="/OrderManagement" component={OrderManagement} />
        <Route path="/PointOfSale" component={PointOfSale} />
        <Route path="/ServiceManagement" component={ServiceManagement} />
        <Route
          path="/ConstructionManagement"
          component={ConstructionManagement}
        />
        <Route path="/Logistics" component={Logistics} />
        <Route
          path="/FieldServiceManagementSoftware"
          component={FieldServiceManagementSoftware}
        />

        {/* Productivity Software tab pages */}
        <Route path="/bigdata" component={BigData} />
        <Route path="/machine-learning" component={MachineLearning} />
        <Route path="/aws" component={AWS} />
        <Route path="/dataiku" component={Dataiku} />
        <Route path="/snowflake" component={Snowflake} />
        <Route path="/microsoft-azure" component={MicrosoftAzure} />
        <Route path="/cloud-foundation" component={CloudFoundation} />
        <Route path="/ai-strategy" component={AIStrategy} />
        <Route path="/data-strategy" component={DataStrategy} />
        <Route path="/data-science" component={DataScience} />
        <Route path="/data-engineering" component={DataEngineering} />
        <Route path="/managed-mlops" component={ManagedMLOps} />

        {/* expedient tab pages */}
        <Route path="/careers" component={Careers} />
        <Route path="/ebooks" component={Ebooks} />
        <Route path="/videos" component={Videos} />
        <Route path="/blog" component={Blog} />
        <Route path="/faq" component={FAQ} />

        {/* other pages */}
        <Route path="/comingsoon" component={ComingSoon} />
        <Route path="/student-registration" component={StudentRegistration} />
      </Switch>
    </ScrollIntoView>
  );
}

export default Router;
