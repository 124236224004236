import React from "react";

export default function Ebooks() {
  return (
    <div>
      {/* Services Section Start */}
      <div className="rs-services style2 rs-services-style2 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container custom">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                {/* <div className="image-part">
                                    <img src="assets/images/services/style2/1.jpg" alt="" />
                                </div> */}
                <div className="content-part">
                  <h3 className="title">
                    <a href="/">Hadoop</a>
                  </h3>
                  <div className="desc">
                    Instead Of Using One Large Computer To Store And Process The
                    Data, Hadoop Allows Clustering Multiple Computers To Analyze
                    Massive Datasets In Parallel More Quickly
                  </div>
                  <p className="mt-30 d-flex justify-content-end">
                    <a
                      href="assets/pdf/hadoop.pdf"
                      download
                      className="text-danger"
                    >
                      Download ebook
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                {/* <div className="image-part">
                                    <img src="assets/images/services/style2/2.jpg" alt="" />
                                </div> */}
                <div className="content-part">
                  <h3 className="title">
                    <a href="/">Machine Learning</a>
                  </h3>
                  <div className="desc">
                    We are a team of experienced professionals who are
                    determined to provide efficient IT solutions. Our team of ML
                    engineers has always got your back.{" "}
                  </div>
                  <p className="mt-30 d-flex justify-content-end">
                    <a
                      href="assets/pdf/ai.pdf"
                      download
                      className="text-danger"
                    >
                      Download ebook
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                {/* <div className="image-part">
                                    <img src="assets/images/services/style2/3.jpg" alt="" />
                                </div> */}
                <div className="content-part">
                  <h3 className="title">
                    <a href="/">Artificial Intelligence</a>
                  </h3>
                  <div className="desc">
                    Artificial Intelligence (AI) is a branch of Science which
                    deals with helping machines find solutions to complex
                    problems in a more human-like fashion.
                  </div>
                  <p className="mt-30 d-flex justify-content-end">
                    <a
                      href="assets/pdf/ai.pdf"
                      download
                      className="text-danger"
                    >
                      Download ebook
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
    </div>
  );
}
