import React, { FunctionComponent } from "react";

import { Link } from "react-router-dom";

const Footer: FunctionComponent = () => {
  return (
    <>
      {/* Footer Start */}
      <footer id="rs-footer" className="rs-footer style1">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 md-mb-10">
                <div className="footer-logo mb-40">
                  <a href="/">
                    <img src="assets/images/brand-logo.png" alt="logo" />
                  </a>
                </div>
                <div className="textwidget white-color pb-40">
                  <p>
                    SuchiERP combines the best of technical teams, futuristic
                    innovations, and streamlined processes to offer you
                    comprehensive business solutions.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 md-mb-10 pl-55 md-pl-15">
                <h3 className="footer-title">Products</h3>
                <ul className="site-map">
                  <li>
                    <Link to="/Overview">Overview</Link>
                  </li>
                  <li>
                    <Link to="/FinancialManagement">Financial Management</Link>
                  </li>
                  <li>
                    <Link to="/ProjectAccounting">Project Accounting</Link>
                  </li>
                  <li>
                    <Link to="/CRM">
                      Customer Relationship Management (CRM)
                    </Link>
                  </li>
                  <li>
                    <Link to="/Payroll">Payroll</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 md-mb-10">
                <h3 className="footer-title">Contact Info</h3>
                <ul className="address-widget">
                  <li>
                    <i className="flaticon-location" />
                    <div className="desc">
                      6701 Highway Blvd #211, <br />
                      Katy, Texas 77494
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-call" />
                    <div className="desc">
                      <a href="tel:(+880)15569569365">(+1) 832-955-1312 </a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-email" />
                    <div className="desc">
                      <a href="mailto:info@suchierp.com">info@suchierp.com</a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-clock-1" />
                    <div className="desc">Office Hours: 9AM - 5PM</div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <h3 className="footer-title">Stay with us</h3>
                <p className="widget-desc white-color">
                  Stay up to update with our latest news and cloud ERP products.
                </p>
                {/* <p>
                                    <input type="email" name="EMAIL" placeholder="Your email address" required />
                                    <input type="submit" defaultValue="Subscribe Now" />
                                </p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
                    <div className="container">
                        <div className="row y-middle">                            
                            <div className="col-lg-12">
                                <div className="copyright text-lg-start text-center ">
                                    <p>© 2021 SuchiERP - Consulting Company Inc. developed by <a href="https://newdevex.com">NEWDEVEX</a> . All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
      </footer>
      {/* Footer End */}

      {/* start scrollUp  */}
      <div id="scrollUp" className="orange-color">
        <i className="fa fa-angle-up" />
      </div>
      {/* End scrollUp  */}
    </>
  );
};

export default Footer;
