import React from "react";
import Img15 from "../../../assets/images/15.png";
import Img16 from "../../../assets/images/16.png";
import Img17 from "../../../assets/images/17.png";
import Img18 from "../../../assets/images/18.png";
import Img19 from "../../../assets/images/19.png";
import Img20 from "../../../assets/images/20.png";
import Img21 from "../../../assets/images/21.png";

export default function CRM() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle mt-20">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-20">Why choose SuchiERP?</h2>
                <p className="mt-10">
                  SuchiERP offers the best functionality, technology, and
                  intuition to collect, manage, and coordinate information for
                  your business. With cloud deployment, easy integration of the
                  tools and systems you already use, and a customized solution
                  for your business, SuchiERP is the obvious choice for your
                  business needs.
                </p>
                <p className="mt-10">
                  In addition, SuchiERP is a comprehensive ERP suite packed with
                  features to help businesses from different industries go
                  sky-high. With 24*7 customer support, SuchiERP is always there
                  to help you overcome any challenges you may face.
                </p>
                <div
                  className="d-flex justify-content-end mt-30 box-content"
                  style={{ backgroundImage: `url(${Img21})` }}
                >
                  <div className="sec-title3 d-flex justify-content-center flex-column">
                    <h2 className="title pb-10 text-center">
                      {" "}
                      So, what are you waiting for?{" "}
                    </h2>
                    <h2 className="title pb-20">
                      Get started with SuchiERP today and see the difference it
                      can make for your business!
                    </h2>
                  </div>
                </div>
                {/* <div className="images-part">
                  <img src={Img11} alt="Images" className="w-100 " />
                </div> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 border-right">
              <section className="mb-30">
                <h2 className="title mb-30 text-underline">
                  Powerful reasons to use SuchiERP Cloud CRM
                </h2>
                <div className="row y-middle mt-20">
                  <div className="col-lg-12 pl-46 md-pl-15">
                    <div className="sec-title3">
                      <h2 className="title">
                        CRM - The ultimate customer management tool
                      </h2>
                      <p className="mt-10">
                        With SuchiERP Cloud CRM, you can easily manage your
                        customer data and sales pipeline in one place. Our
                        powerful software automates your sales and marketing
                        processes, so you can focus on growing your business.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row y-middle mt-20">
                  <div className="col-lg-12 pl-46 md-pl-15">
                    <div className="sec-title3">
                      <h2 className="title">
                        Sales - Increase your sales and close more deals
                      </h2>
                      <p className="mt-10">
                        SuchiERP Cloud CRM gives you the tools you need to
                        increase your sales and close more deals. Our software
                        automates your sales process and provides detailed
                        insights into your pipeline so that you can close more
                        deals faster.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row y-middle mt-20">
                  <div className="col-lg-12 pl-46 md-pl-15">
                    <div className="sec-title3">
                      <h2 className="title">
                        Marketing - Reach more customers and grow your brand
                      </h2>
                      <p className="mt-10">
                        With SuchiERP Cloud CRM, you can easily reach more
                        customers and grow your brand. Our powerful marketing
                        tools let you automate your marketing campaigns and
                        track their performance, so you can see what's working
                        and adjust your strategy on the fly.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="col-sm-6">
              <section className="mb-30">
                <h2 className="title mb-30 text-underline">
                  SuchiERP’s CRM Solutions for Client Support
                </h2>
                <div className="row y-middle mt-20">
                  <div className="col-lg-12 pl-46 md-pl-15">
                    <div className="sec-title3">
                      <h2 className="title">
                        Customer service is the new marketing
                      </h2>
                      <p className="mt-10">
                        With the rise of social media, customers are more
                        connected than ever before. They can and will share
                        their sound (and bad) experiences with your company
                        online, so it's essential to have a strong customer
                        service team in place.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row y-middle mt-20">
                  <div className="col-lg-12 pl-46 md-pl-15">
                    <div className="sec-title3">
                      <h2 className="title">
                        Our CRM solution can help you keep track of your
                        customer interactions
                      </h2>
                      <p className="mt-10">
                        Our CRM (customer relationship management) solution can
                        help you keep track of all your customer interactions in
                        one place. This includes phone calls, emails, live
                        chats, and more.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row y-middle mt-20">
                  <div className="col-lg-12 pl-46 md-pl-15">
                    <div className="sec-title3">
                      <h2 className="title">
                        Our CRM solution can help you resolve customer issues
                        faster
                      </h2>
                      <p className="mt-10">
                        Having our CRM solution in place can help you quickly
                        resolve customer issues. This is because you'll have all
                        the information you need at your fingertips to identify
                        the problem and come up with a resolution.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <section className="mb-30">
            <h2 className="title text-underline">
              SuchiERP Cloud CRM for Your Marketing Needs
            </h2>
            <div className="row y-middle mt-20">
              <div className="col-lg-12 pl-46 md-pl-15">
                <div className="sec-title3">
                  <h2 className="title">
                    Cloud-based customer relationship management software
                  </h2>
                  <p className="mt-10">
                    This helps businesses manage their customer data, sales, and
                    marketing processes in one place.
                  </p>
                </div>
              </div>
            </div>
            <div className="row y-middle mt-20">
              <div className="col-lg-12 pl-46 md-pl-15">
                <div className="sec-title3">
                  <h2 className="title">Marketing automation tools</h2>
                  <p className="mt-10">
                    SuchiERP includes powerful marketing automation tools that
                    help businesses automate their marketing tasks, including
                    email marketing, social media campaigns, and lead
                    generation.
                  </p>
                </div>
              </div>
            </div>
            <div className="row y-middle mt-20">
              <div className="col-lg-12 pl-46 md-pl-15">
                <div className="sec-title3">
                  <h2 className="title">
                    A complete package for your marketing needs
                  </h2>
                  <p className="mt-10">
                    SuchiERP is a complete package for your marketing needs,
                    with everything you need to start quickly and easily. Our
                    ERP is connected or contracted with EVALON and uses its
                    CENPOS for financial transactions. With its intuitive
                    interface and easy-to-use features, SuchiERP is the perfect
                    CRM solution for small businesses and startups. Our ERP is
                    capable of transactions in Cash, checks, credit cards, and
                    virtual currencies.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* Services Section Start */}
      <div className="rs-services style2 rs-services-style2 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container custom">
          <h2 className="title pb-20">
            Fields where SuchiERP Cloud CRM can be used
          </h2>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="image-part">
                  <img src={Img15} alt="" />
                </div>
                <div className="content-part">
                  <h3 className="title mt-10">
                    <a href="/">Sales and Marketing</a>
                  </h3>
                  <div className="desc">
                    Keep track of your leads, customers, and sales pipeline all
                    in one place
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="image-part">
                  <img src={Img16} alt="" />
                </div>
                <div className="content-part">
                  <h3 className="title mt-10">
                    <a href="/">Customer Service</a>
                  </h3>
                  <div className="desc">
                    Improve customer satisfaction with streamlined case
                    management.{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="image-part">
                  <img src={Img17} alt="" />
                </div>
                <div className="content-part">
                  <h3 className="title mt-10">
                    <a href="/">Human Resources</a>
                  </h3>
                  <div className="desc">
                    From onboarding to performance reviews, manage your employee
                    data securely
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="image-part">
                  <img src={Img18} alt="" />
                </div>
                <div className="content-part">
                  <h3 className="title mt-10">
                    <a href="/">Finance</a>
                  </h3>
                  <div className="desc">
                    Get a real-time view of your financial data to make informed
                    decisions
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="image-part">
                  <img src={Img19} alt="" />
                </div>
                <div className="content-part">
                  <h3 className="title mt-10">
                    <a href="/">Information Technology</a>
                  </h3>
                  <div className="desc">
                    Helpdesk, inventory and project management made easy
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="service-wrap">
                <div className="image-part">
                  <img src={Img20} alt="" />
                </div>
                <div className="content-part ">
                  <h3 className="title mt-10">
                    <a href="/">Manufacturing</a>
                  </h3>
                  <div className="desc">
                    Streamline your production process and keep track of your
                    inventory
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
    </div>
  );
}
