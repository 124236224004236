import React from "react";

export default function DataEngineering() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-30">
                  These platforms are usually used in four different ways
                </h2>
                {/* <p className="margin-0 pb-40">AI enables human capabilities of understanding, planning, reasoning, communication and perception to be undertaken by software increasingly effectively, efficiently and at low cost.</p> */}
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Data ingestion and storage of large amounts of data
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Algorithm creation by data scientists
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Automation of the data scientist’s machine learning models
                      and algorithms
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Data visualisation for employees and clients.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Traditional solution architects for systems that involve
                      SQL databases, web servers, SAP installations and other
                      “standard” 12 systems..
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12 md-mb-30 mt-30">
              <div className="images-part">
                <img
                  src="assets/images/pages/data-engineering/1.png"
                  alt="Images"
                  className="w-100 "
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services style3 pt-50 pb-100 md-pt-30 md-pb-70">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">Who Companies Need</h2>
            <p className="services-txt mt-30">
              For a good company it is absolutely important to get well trained
              data engineers and data scientists.
            </p>
            <p className="services-txt mt-30">
              Think of the data scientist as the professional race car driver. A
              fit athlete with talent and driving skills like you have never
              seen.
            </p>
            <p className="services-txt mt-30">
              It is a common ground between both that drives innovation. Spark
              gives data scientists the tools to do analytics and helps
              engineers to bring the data scientist’s algorithms into
              production. After all, those two decide how good the data platform
              is, how good the analytics insight is and how fast the whole
              system gets into a production ready state.
            </p>
            <div className="mt-30 mb-10">
              <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/SpaFPPByOhM"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section End */}
      <div className="rs-services style3 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">
              Basic skills of computer engineering: <br />
              Get Familiar With Github Why this is important:
            </h2>
            <p className="service-txt">
              The main components that make up cloud infrastructure break down
              into four key groups:
            </p>
            {/* <img src="assets/images/pages/ai/2.png" alt="Services" className='mt-30' /> */}
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 md-mb-50">
              <div className="services-item">
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">
                      One of the major problems with coding is to keep track of
                      changes.
                    </a>
                  </h2>
                  <h2 className="services-title">
                    <a href="/">
                      It is also almost impossible to maintain a program you
                      have multiple versions of
                    </a>
                  </h2>
                  <p className="service-txt">
                    Let’s say you work on a Spark application and your colleges
                    need to make changes while you are on holiday. Without some
                    code management they are in huge trouble:
                  </p>
                  <p className="service-txt">
                    Where is the code? What have you changed last? Where is the
                    documentation? How do we mark what we have changed?
                  </p>
                  <p className="service-txt">
                    But if you put your code on GitHub your colleges can find
                    your code. They can understand it through your documentation
                    (please also have in-line comments)
                  </p>
                  <p className="service-txt">
                    Developers can pull your code, make a new branch and do the
                    changes. After your holiday you can inspect what they have
                    done and merge it with your original code. and you end up
                    having only one application.Where to learn: Check out the
                    GitHub Guides page where you can learn all the basics:
                    https://guides.github.com/introduction/flow/..This great
                    GitHub commands cheat sheet saved my butt multiple times:
                    https://www.atlassian.com/git/tutorials/atlassiangit-cheatsheet
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
    </div>
  );
}
