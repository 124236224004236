import React from "react";
import "react-tabs/style/react-tabs.css";
import Img24 from "../../../assets/images/24.svg";

export default function Overview() {
  const Img8 = require("../../../assets/images/8.mp4");
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle mt-30">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-20">
                  ERP options suited to your sector
                </h2>
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Industry-specific features that are simply modifiable and
                      adaptable to your precise requirements.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Future-proof infrastructure
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Flexible deployments, a uniform data model, and simpler
                      integrations
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">superior performance</span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Pricing based on your requirements.
                    </span>
                  </li>
                </ul>
                <p className="mt-10">
                  Collaboration is facilitated through data sharing and
                  cross-team processes, while productivity is increased by
                  automation powered by Artificial Intelligence. The greatest
                  ratings for usability and user experience go to{" "}
                  <b>SuchiERP</b>, making it easier to promote adoption and
                  collaboration across the whole organization. It is simple to
                  integrate other tools and programs, whether through the
                  expansive SuchiERP Marketplace or by bespoke connections that
                  are user- and developer-friendly.
                </p>
              </div>
            </div>
            <div className="col-lg-12 mt-0">
              <div className="images-part">
                <img src={Img24} alt="Images" className="w-100 " />
              </div>
            </div>
          </div>
          <div className="row y-middle mt-30">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title">SuchiERP Cloud ERP</h2>
                <p className="mt-10">
                  With solutions that are easy to use, configurable, and
                  designed to meet your unique needs, you can advance your
                  business. Profitability and productivity should rise right
                  away. Make sure you can adapt well in the future.
                </p>
              </div>
            </div>
          </div>
          <div className="row y-middle mt-30">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title">A business that is connected</h2>
                <p className="mt-10">
                  A cloud-native platform designed to integrate with your
                  operations and link your organization across time and region
                  can provide a solution that is adapted to your demands and
                  prepared to expand with your business. The consumer is
                  prioritized by our flexible license, which is expandable to
                  match your changing demands.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services style3 pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <video width="100%" height="400" controls>
            <source src={Img8} type="video/mp4" />
          </video>
        </div>
      </div>

      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle mt-30">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-20">Analysis of the ERP industry</h2>
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      The capabilities and advancements of ERP are highlighted.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      comprehensive evaluations of the 16 ERP vendors under
                      consideration.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      This study offers helpful details about the functioning
                      and usability of each vendor's system.
                    </span>
                  </li>
                </ul>
                <p className="mt-10">
                  Collaboration is facilitated through data sharing and
                  cross-team processes, while productivity is increased by
                  automation powered by Artificial Intelligence. The greatest
                  ratings for usability and user experience go to{" "}
                  <b>SuchiERP</b>, making it easier to promote adoption and
                  collaboration across the whole organization. It is simple to
                  integrate other tools and programs, whether through the
                  expansive SuchiERP Marketplace or by bespoke connections that
                  are user- and developer-friendly.
                </p>
              </div>
            </div>
          </div>
          <div className="row y-middle mt-30">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title">
                  There is built-in enterprise-level security.
                </h2>
                <p className="mt-10">
                  SuchiERP was designed to satisfy the strict security
                  requirements of contemporary business companies. You'll have
                  role-based permissions and industry best practices for data
                  protection and privacy in addition to safe internal and
                  external cooperation made possible by role-based permissions.
                </p>
                <p className="mt-10">
                  Both midmarket expansion businesses and enterprise branch
                  offices are planned users of SuchiERP. A single point of
                  contact is provided through intuitive solutions, which enable
                  automation without complexity, compliance without rigidity,
                  and continual improvement without unpredictability. All of
                  your data is connected and accessible, and there are no
                  bloated features or rigid procedures that slow you down.
                </p>
                <p className="mt-10">
                  Our clients are our most valuable asset, and their success is
                  our top priority. Our licensing structure, data regulations,
                  and customer service methods are unmatched in the market and
                  designed with customers' requirements, not stockholders', in
                  mind.
                </p>
              </div>
            </div>
          </div>

          <div className="row y-middle mt-30">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title">
                  Examine the opportunities with a customer-focused,
                  future-proof ERP platform.
                </h2>
                <p className="mt-10">
                  The key selling point of SuchiERP is its modular architecture,
                  which enables both expert developers and average users to
                  create new report types, connect to external applications, and
                  incorporate business logic using low-code/no-code
                  technologies.
                </p>
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Using SuchiERP Cloud ERP</span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Atomic Investigation</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Tabs className="container pt-20 pb-20">
        <TabList>
          <Tab>AI Strategy</Tab>
          <Tab>Data Strategy</Tab>
          <Tab>Data Science</Tab>
          <Tab>Data Engineering</Tab>
        </TabList>

        <TabPanel>
            Tab-1
        </TabPanel>
        <TabPanel>
          <AIStrategy />
        </TabPanel>
        <TabPanel>
          <DataStrategy />
        </TabPanel>
        <TabPanel>
          <DataScience />
        </TabPanel>
      </Tabs> */}
    </div>
  );
}
