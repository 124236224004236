import React from "react";
import Img27 from "../../../assets/images/27.svg";
import Img28 from "../../../assets/images/28.svg";

export default function PointOfSale() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-0 pb-20 md-pt-00 md-pb-20"
      >
        <div className="container">
          <div className="row y-middle mt-0">
            <div className="col-lg-6 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-20">
                  The place where a client/customer executes the payment for
                  product or services and where sales taxes might be payable. We
                  can sell intelligently our brand new retail point of sale
                  #Premium software.
                </h2>
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Recipe Management & Costing
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Purchase Management </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Stock Management & Inventory
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Powerful Inventory Reporting
                    </span>
                  </li>
                </ul>
                <p className="mt-10">
                  Collaboration is facilitated through data sharing and
                  cross-team processes, while productivity is increased by
                  automation powered by Artificial Intelligence. The greatest
                  ratings for usability and user experience go to{" "}
                  <b>SuchiERP</b>, making it easier to promote adoption and
                  collaboration across the whole organization. It is simple to
                  integrate other tools and programs, whether through the
                  expansive SuchiERP Marketplace or by bespoke connections that
                  are user- and developer-friendly.
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-30 mt-30">
              <div className="images-part">
                <img src={Img27} alt="Images" className="w-100 " />
              </div>
            </div>
          </div>
          <div className="row y-middle mt-30">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title">
                  A Point Of Sale business that is connected always with us!
                </h2>
                <p className="mt-10">
                  A cloud-native platform designed to integrate with your
                  operations and link your organization across time and region
                  can provide a solution that is adapted to your demands and
                  prepared to expand with your business. The consumer is
                  prioritized by our flexible license, which is expandable to
                  match your changing demands.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services style3 pt-0 pb-0 md-pt-0 md-pb-0">
        <div className="container">
          <img src={Img28} alt=""/>
        </div>
      </div>
    </div>
  );
}
