import React from "react";

export default function MarketPlace() {
  return (
    <div>
      <div className="rs-inner-blog pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container custom">
          <div className="row">
            <div className="col-lg-4 col-md-12 order-last">
              <div className="widget-area">
                <div className="search-widget mb-50">
                  <div className="search-wrap">
                    <input
                      type="search"
                      placeholder="Searching..."
                      name="s"
                      className="search-input"
                      value=""
                    />
                    <button type="submit" value="Search">
                      <i className="flaticon-search"></i>
                    </button>
                  </div>
                </div>
                <div className="recent-posts mb-50">
                  <div className="widget-title">
                    <h3 className="title">Recent Posts</h3>
                  </div>
                  <div className="recent-post-widget no-border">
                    <div className="post-img">
                      <a href="/">
                        <img
                          src="assets/images/blog/inner/style2/1.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="post-desc">
                      <a href="/">
                        {" "}
                        Customer Onboarding Strategy: A Guide to Class{" "}
                      </a>
                      <span className="date-post">
                        {" "}
                        <i className="fa fa-calendar"></i> Aug 8, 2021{" "}
                      </span>
                    </div>
                  </div>
                  <div className="recent-post-widget">
                    <div className="post-img">
                      <a href="/">
                        <img
                          src="assets/images/blog/inner/style2/2.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="post-desc">
                      <a href="/">
                        {" "}
                        How investing in dependend increasing to business{" "}
                      </a>
                      <span className="date-post">
                        {" "}
                        <i className="fa fa-calendar"></i> Aug 8, 2021{" "}
                      </span>
                    </div>
                  </div>
                  <div className="recent-post-widget">
                    <div className="post-img">
                      <a href="/">
                        <img
                          src="assets/images/blog/inner/style2/3.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="post-desc">
                      <a href="/">
                        7 Productivity tips to avoid burnout when working
                      </a>
                      <span className="date-post">
                        {" "}
                        <i className="fa fa-calendar"></i> Aug 8, 2021{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="categories">
                  <div className="widget-title">
                    <h3 className="title">Categories</h3>
                  </div>
                  <ul>
                    <li>
                      <a href="branding.html">Branding</a>
                    </li>
                    <li>
                      <a href="digital-marketing.html">Digital Marketing</a>
                    </li>
                    <li>
                      <a href="e-commerce.html">E-commerce</a>
                    </li>
                    <li>
                      <a href="graphic-design.html">Graphic Design</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8 pr-35 md-pr-15 md-mt-50">
              <div className="row">
                <div className="col-lg-12 mb-50">
                  <div className="blog-item">
                    <div className="blog-img">
                      <a href="/">
                        <img
                          src="assets/images/blog/inner/style1/1.jpg"
                          alt=""
                        />
                      </a>
                      <ul className="post-categories">
                        <li>
                          <a href="/">Branding</a>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-content">
                      <h3 className="blog-title">
                        <a href="/">
                          Customer onboarding there business classes
                        </a>
                      </h3>
                      <div className="blog-meta">
                        <ul className="btm-cate">
                          <li>
                            <div className="blog-date">
                              <i className="fa fa-calendar-check-o"></i> Aug 7,
                              2021
                            </div>
                          </li>
                          <li>
                            <div className="author">
                              <i className="fa fa-user-o"></i> rstheme
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="blog-desc">
                        We denounce with righteous indige nation and dislike men
                        who are so beguiled and demo realized by the charms of
                        pleasure of the moment, so blinded by desire, that...
                      </div>
                      <div className="blog-button">
                        <a href="/" className="blog-btn">
                          Continue Reading
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mb-50">
                  <div className="blog-item">
                    <div className="blog-img">
                      <a href="/">
                        <img
                          src="assets/images/blog/inner/style1/2.jpg"
                          alt=""
                        />
                      </a>
                      <ul className="post-categories">
                        <li>
                          <a href="/">Branding</a>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-content">
                      <h3 className="blog-title">
                        <a href="/">
                          How Investing in Self-Dependent Increase Business
                        </a>
                      </h3>
                      <div className="blog-meta">
                        <ul className="btm-cate">
                          <li>
                            <div className="blog-date">
                              <i className="fa fa-calendar-check-o"></i> Aug 7,
                              2021
                            </div>
                          </li>
                          <li>
                            <div className="author">
                              <i className="fa fa-user-o"></i> rstheme
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="blog-desc">
                        We denounce with righteous indige nation and dislike men
                        who are so beguiled and demo realized by the charms of
                        pleasure of the moment, so blinded by desire, that...
                      </div>
                      <div className="blog-button">
                        <a href="/" className="blog-btn">
                          Continue Reading
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mb-50">
                  <div className="blog-item">
                    <div className="blog-img">
                      <a href="/">
                        <img
                          src="assets/images/blog/inner/style1/3.jpg"
                          alt=""
                        />
                      </a>
                      <ul className="post-categories">
                        <li>
                          <a href="/">Digital Marketing</a>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-content">
                      <h3 className="blog-title">
                        <a href="/">
                          7 Productivity Tips to Avoid Burnout When Working
                        </a>
                      </h3>
                      <div className="blog-meta">
                        <ul className="btm-cate">
                          <li>
                            <div className="blog-date">
                              <i className="fa fa-calendar-check-o"></i> Aug 7,
                              2021
                            </div>
                          </li>
                          <li>
                            <div className="author">
                              <i className="fa fa-user-o"></i> rstheme
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="blog-desc">
                        We denounce with righteous indige nation and dislike men
                        who are so beguiled and demo realized by the charms of
                        pleasure of the moment, so blinded by desire, that...
                      </div>
                      <div className="blog-button">
                        <a href="/" className="blog-btn">
                          Continue Reading
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mb-50">
                  <div className="blog-item">
                    <div className="blog-img">
                      <a href="/">
                        <img
                          src="assets/images/blog/inner/style1/4.jpg"
                          alt=""
                        />
                      </a>
                      <ul className="post-categories">
                        <li>
                          <a href="blog-single">Digital Marketing</a>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-content">
                      <h3 className="blog-title">
                        <a href="/">
                          Email Marketing Tips That Will Increase Your Sales
                        </a>
                      </h3>
                      <div className="blog-meta">
                        <ul className="btm-cate">
                          <li>
                            <div className="blog-date">
                              <i className="fa fa-calendar-check-o"></i> Aug 7,
                              2021
                            </div>
                          </li>
                          <li>
                            <div className="author">
                              <i className="fa fa-user-o"></i> rstheme
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="blog-desc">
                        We denounce with righteous indige nation and dislike men
                        who are so beguiled and demo realized by the charms of
                        pleasure of the moment, so blinded by desire, that...
                      </div>
                      <div className="blog-button">
                        <a href="/" className="blog-btn">
                          Continue Reading
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="pagination-area">
                    <div className="nav-link">
                      <span className="page-number white-color">1</span>
                      <a href="/" className="page-number">
                        2
                      </a>
                      <a href="/" className="page-number border-none">
                        Next
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
