import React from "react";
import Img32 from "../../../assets/images/32.png";

export default function ServiceManagement() {  
  const Img33 = require("../../../assets/images/33.gif");

  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle mt-0">
            <div className="col-lg-6">
              <div className="images-part">
                <img src={Img33} alt="Images" className="w-100 " />
              </div>
            </div>
            <div className="col-lg-6 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-20">
                  Delivering Excellent Results Across Industries
                </h2>
                <p className="mt-10">
                  <b>Improved customer visibilit</b>y integrates service
                  management with SuchiERP CRM provides access to sales history,
                  support cases, and activity history.
                </p>

                <p className="mt-10">
                  <b>Contract management</b> does sales and service contract
                  management, maintenance routine maintenance and repair
                  scenarios. Define invoicing procedures and Generate service
                  orders with flexible frequency.
                </p>
                <p className="mt-10">
                  <b>Appointment scheduling</b> Schedules technicians based on
                  availability, location, skills, licenses, or areas of service.
                  Accurate schedule helps to reduce overtime costs and maximize
                  customer satisfaction.
                </p>
                <p className="mt-10">
                  <b>Inventory Planning</b> will Track inventory across
                  warehouses and mobile vehicles. Use Replenish stock and
                  purchase orders to ensure stock.
                </p>
                <p className="mt-10">
                  <b>Improved mobility</b> empowers technicians with a
                  comprehensive mobile app with GPS location, electronic
                  signature, credit card payment, mobile phone Expense receipts,
                  voice dictation for notes, image downloads and appointment
                  details. Field technicians can view service history and device
                  information.
                </p>
                <p className="mt-10">
                  <b>Project management</b> will track multi-stage projects.
                  Compare the original with the revised version budget. Manage
                  payments with fixed, time-limited, or fixed-price project
                  costs. Receipt Service orders and appointments through the
                  project instead of the Service Management.
                </p>
              </div>
            </div>
          </div>

          <div className="row y-middle mt-30">
            <div className="col-lg-6 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-20">
                  SuchiERP Service Management software is fully integrated with
                  your ERP system
                </h2>

                <p className="mt-10">
                  SuchiERP service management allows you to quickly capture
                  service needs, assign them faster, and track progress in real
                  time. Effective planning leads to increased revenue and
                  improved customer satisfaction. SuchiERP Service Management
                  software is fully integrated with your ERP system, providing
                  seamless integration with inventory, purchasing and projects.
                </p>
                <p className="mt-10">
                  And, Features include contract management, appointment
                  scheduling, and a comprehensive mobile app for
                  service-oriented businesses, wholesale distributors,
                  construction companies, and fabricators for delivery and
                  installation operations. remotely located or repaired. Use
                  Device Management to track and maintain customer or company
                  owned equipment and optimize routes with SuchiERP for WorkWave
                  Route Optimization Native Connector.
                </p>
              </div>
            </div>
            <div className="col-lg-6 md-mb-30 mt-30">
              <div className="images-part">
                <img src={Img32} alt="Images" className="w-100 " />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
