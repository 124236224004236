import React from "react";

export default function AIStrategy() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/pages/ai/5.gif"
                  alt="Images"
                  className="w-100"
                />
              </div>
            </div>
            <div className="col-lg-6 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-30">
                  Importance of Artificial intelligence (AI):
                </h2>
                <p className="margin-0 pb-40">
                  AI enables human capabilities of understanding, planning,
                  reasoning, communication and perception to be undertaken by
                  software increasingly effectively, efficiently and at low
                  cost.
                </p>
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">A more or less flexible</span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Efficient approach can be taken depending on the
                      requirements established, which influences how artificial
                      the intelligent behavior appears
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Artificial intelligence (AI) is a wide-ranging branch of
                      computer science concerned with building smart machines
                      capable of performing tasks that typically require human
                      intelligence.{" "}
                    </span>
                  </li>
                </ul>
                {/* <div className="btn-part mt-42">
                                    <a href="/" className="readon consultant paste-btn more" href="https://www.udemy.com/course/the-data-strategy-course-building-a-data-driven-business/">Know More</a>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section End */}
      <div className="rs-services style3 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">
              4 key AI concepts you need to understand and Face recognition.
            </h2>
            <img
              src="assets/images/pages/ai/3.gif"
              alt="Services"
              className="mt-30 w-100"
            />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 md-mb-50">
              <div className="services-item">
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Categorization</a>
                  </h2>
                  <p className="services-txt">
                    {" "}
                    The first step to building an AI solution is creating what I
                    call “design intent metrics,” which are used to categorize
                    the problem.
                  </p>
                  <p className="services-txt">
                    {" "}
                    Whether users are trying to build a system that can play
                    Jeopardy, help a doctor diagnose cancer, or help an IT
                    administrator diagnose wireless problems, users need to
                    define metrics that allow the problem to be broken into
                    smaller pieces. In wireless networking, for example, key
                    metrics are user connection time, throughput, coverage, and
                    roaming. In cancer diagnosis, key metrics are white cell
                    count, ethnic background, and X-ray scans.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50">
              <div className="services-item">
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Classification</a>
                  </h2>
                  <p className="services-txt">
                    Once users have the problem categorized into different
                    areas, the next step is to have classifiers for each
                    category that will point users in the direction of a
                    meaningful conclusion. For example, when training an AI
                    system to play Jeopardy, users must first classify a
                    question as being literal in nature or a play on words, and
                    then classify by time, person, thing, or place. In wireless
                    networking, once users know the category of a problem (e.g.
                    a pre- or post-connection problem), users need to start
                    classifying what is causing the problem: association,
                    authentication, dynamic host configuration protocol (DHCP),
                    or other wireless, wired, and device factors.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mb-50">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/1.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Collaborative filtering</a>
                  </h2>
                  <p className="services-txt">
                    Most people experience collaborative filtering when they
                    pick a movie on Netflix or buy something from Amazon and
                    receive recommendations for other movies or items they might
                    like. Beyond recommenders, collaborative filtering is also
                    used to sort through large sets of data and put a face on an
                    AI solution.
                  </p>
                  <p className="services-txt">
                    This is where all the data collection and analysis is turned
                    into meaningful insight or action. Whether used in a game
                    show, or by a doctor, or by a network administrator,
                    collaborative filtering is the means to providing answers
                    with a high degree of confidence. It is like a virtual
                    assistant that helps solve complex problems.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
      <div className="rs-services style3 pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">
              How will Artificial Intelligence and Internet of Things change the
              world?
            </h2>
            <div className="mt-30 mb-30">
              <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/Cx5aNwnZYDc"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {/* Partner Section Start */}
      <div className="rs-patter-section black-bg pt-80 pb-75">
        <div className="container custom">
          <div
            className="rs-carousel owl-carousel"
            data-loop="true"
            data-items={5}
            data-margin={30}
            data-autoplay="true"
            data-hoverpause="true"
            data-autoplay-timeout={5000}
            data-smart-speed={800}
            data-dots="false"
            data-nav="false"
            data-nav-speed="false"
            data-md-device={5}
            data-md-device-nav="false"
            data-md-device-dots="false"
            data-center-mode="false"
            data-ipad-device2={4}
            data-ipad-device-nav2="false"
            data-ipad-device-dots2="false"
            data-ipad-device={4}
            data-ipad-device-nav="false"
            data-ipad-device-dots="false"
            data-mobile-device={2}
            data-mobile-device-nav="false"
            data-mobile-device-dots="false"
          >
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/1.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/1.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/2.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/2.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/3.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/3.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/4.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/4.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/5.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/5.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Partner Section End */}
    </div>
  );
}
