import React from "react";
import { Link } from "react-router-dom";

//components
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Img1 from "../../assets/images/1.svg";
import Img2 from "../../assets/images/2.svg";
import Img3 from "../../assets/images/3.png";
import Img42 from "../../assets/images/42.svg";

import Img43 from "../../assets/images/43.svg";
import Img44 from "../../assets/images/44.svg";
import Img45 from "../../assets/images/45.svg";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      campaigns: [],
      isLoading: false,
    };
  }

  render() {
    return (
      <>
        <div>
          {/*Preloader start here*/}
          <div id="pre-load">
            <div id="loader" className="loader">
              <div className="loader-container">
                <div className="loader-icon">
                  <img src="assets/images/fav.png" alt="SuchiERP Business" />
                </div>
              </div>
            </div>
          </div>
          {/*Preloader area end here*/}

          {/* Slider Start */}
          <div id="rs-slider" className="rs-slider slider3">
            <div className="bend niceties">
              <div id="nivoSlider" className="slides hidden-xs">
                <img src={Img1} alt="" title="#slide-1" />
                <img src={Img2} alt="" title="#slide-2" />
              </div>
              <div id="nivoSlider" className="slides visible-xs">
                <img src="assets/images/m1.png" alt="" title="#slide-1" />
                <img src="assets/images/2.png" alt="" title="#slide-2" />
              </div>
              {/* Slide 1 */}
              <div id="slide-1" className="slider-direction">
                <div className="content-part">
                  <div className="container">
                    {/* <div className="slider-des">
                                            <div className="sl-subtitle">A project that functions </div>
                                            <h1 className="sl-title">SuchiERP is equipped with <br/> the latest technology</h1>
                                        </div>
                                        <div className="desc">We fuse innovation and business plans to create <br />excellent business solutions!</div>
                                        <div className="slider-bottom ">
                                            <a href="/" className="readon consultant slider">Discover More</a>
                                        </div> */}
                  </div>
                </div>
              </div>
              {/* Slide 2 */}
              <div id="slide-2" className="slider-direction">
                <div className="content-part">
                  <div className="container"></div>
                </div>
              </div>
            </div>
          </div>
          {/* Slider End */}

          <div class="rs-services style7 black-bg3 pt-30 pb-30">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 col-md-6 md-mb-30">
                  <div class="services-item">
                    <div class="services-icon">
                      <img
                        src="assets/images/services/style7/icons/1.png"
                        alt="Images"
                      />
                    </div>
                    <div class="services-content">
                      <h2 class="title"> Extraordinary Functionality</h2>
                      <p class="services-txt">
                        {" "}
                        Remote and multi-device accessibility
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 md-mb-30">
                  <div class="services-item">
                    <div class="services-icon">
                      <img
                        src="assets/images/services/style7/icons/2.png"
                        alt="Images"
                      />
                    </div>
                    <div class="services-content">
                      <h2 class="title">End-to-End Solutions</h2>
                      <p class="services-txt">
                        {" "}
                        Packed as a comprehensive ERP suite.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="services-item">
                    <div class="services-icon">
                      <img
                        src="assets/images/services/style7/icons/3.png"
                        alt="Images"
                      />
                    </div>
                    <div class="services-content">
                      <h2 class="title"> Well-Received by Customers</h2>
                      <p class="services-txt"> 24*7 customer support</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="rs-about main-home pt-70 pb-70 md-pt-70 md-pb-70">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 md-mb-50">
                  <div class="images-part">
                    <img src={Img3} alt="Images" className="w-100" />
                  </div>
                  <p className="mt-40">
                    Consider how <b>SuchiERP's appealing user interface</b>,
                    role-based dashboards, scalable workflows, native mobile
                    apps, and thorough reporting and analytics assist you in
                    building a modern foundation for growth.
                  </p>
                </div>
                <div class="col-lg-6 pl-46 md-pl-15">
                  <div class="sec-title3">
                    <h2 class="title pb-10">
                      A product tour introducing Cloud ERP
                    </h2>
                    <p class="margin-0 pb-10">
                      Schedule Personalized SuchiERP Cloud ERP Overview with
                      real-time observations available whenever and anywhere.
                      With the help of SuchiERP's cloud ERP application suite,
                      you can efficiently manage your business, assess client
                      interactions, and obtain proactive business insights at
                      any time, from any location, on any device.
                    </p>
                    <p class="margin-0 pb-10">
                      Maintain daily operations at work or while traveling by
                      having access to business apps and data around-the-clock,
                      seven days a week, using native mobile applications and
                      collaboration solutions created for mobile and telework
                      scenarios.
                    </p>
                    <ul class="icon-item">
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">Cloud ERP from SuchiERP.</span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          Ten minutes of watching it function.
                        </span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">One-Hour Tour</span>
                      </li>
                    </ul>
                    <div class="btn-part mt-42">
                      <Link
                        to="/Overview"
                        class="readon consultant paste-btn more"
                      >
                        Know More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Services Section Start */}
          <div className="rs-services home-style2 pt-50 pb-50 md-pt-50 md-pb-50 gray-bg">
            <div className="container">
              <div className="sec-title2 text-center md-left mb-40">
                <h2 className="title pb-20">
                  Our Services helps you expand your <br /> business
                </h2>
                <p>
                  The cloud-based ERP system SuchiERP is completely integrated
                  and can meet all of your company's management requirements,
                  both today and in the future.
                </p>
              </div>
              <div className="row y-middle">
                <div className="col-lg-6 md-mb-50 pr-30 md-pr-l5">
                  <div className="services-item mb-45">
                    <div className="services-icon">
                      <img
                        src="assets/images/services/style8/ai.svg"
                        alt="Images"
                      />
                    </div>
                    <div className="services-text">
                      <h3 className="title">
                        <Link to="ai-strategy">Occupational relationships</Link>
                      </h3>
                      <p className="services-txt">
                        It is simple to integrate with the collaborative tools
                        of your choice or requirements and was created for
                        telework, best of technical teams and mobile situations.
                      </p>
                    </div>
                  </div>
                  <div className="services-item mb-45">
                    <div className="services-icon">
                      <img
                        src="assets/images/services/style8/de.svg"
                        alt="Images"
                      />
                    </div>
                    <div className="services-text">
                      <h3 className="title">
                        <Link to="data-engineering">Data engineering</Link>
                      </h3>
                      <p className="services-txt">
                        Data Engineers at SuchiERP manage and transform raw data
                        to that of high value. They assist in helping you build
                        strong and effective cloud platforms.
                      </p>
                    </div>
                  </div>
                  {/* <div className="services-item mb-45">
                                        <div className="services-icon">
                                            <img src="assets/images/services/style8/aw.svg" alt="Images" />
                                        </div>
                                        <div className="services-text">
                                            <h3 className="title"><Link to="home">Award winning</Link></h3>
                                            <p className="services-txt">SuchiERP is an award-winning company that excels in customer satisfaction. We prioritize our customers and that is what makes us unique.</p>
                                        </div>
                                    </div>                                 */}
                  <div className="services-item">
                    <div className="services-icon">
                      <img
                        src="assets/images/services/style8/snowflake.svg"
                        alt="Images"
                      />
                    </div>
                    <div className="services-text">
                      <h3 className="title">
                        <Link to="snowflake-administration">
                          Enterprise flexibility
                        </Link>
                      </h3>
                      <p className="services-txt">
                        Invest in high availability, frequent backups, and
                        several data storage sites to be ready for any
                        imaginable disaster.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 pl-40 md-pl-15">
                  <div className="services-item mb-45">
                    <div className="services-icon">
                      <img
                        src="assets/images/services/style8/ds.svg"
                        alt="Images"
                      />
                    </div>
                    <div className="services-text">
                      <h3 className="title">
                        <Link to="data-science">
                          Confident about the future
                        </Link>
                      </h3>
                      <p className="services-txt">
                        {" "}
                        Your company will be prepared for the future if you have
                        a mobile-first, telework architecture that guarantees
                        operational continuity and business resilience.
                      </p>
                    </div>
                  </div>
                  <div className="services-item mb-45">
                    <div className="services-icon">
                      <img
                        src="assets/images/services/style3/2.png"
                        alt="Images"
                      />
                    </div>
                    <div className="services-text">
                      <h3 className="title">
                        <Link to="data-strategy">Continual time</Link>
                      </h3>
                      <p className="services-txt">
                        {" "}
                        To examine the data that relates to your business, you
                        can do so using any device, from any location, at any
                        time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Counter Section End */}
            <div className="rs-counter style1">
              <div className="container">
                <div className="counter-border-top">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 md-mb-30">
                      <div className="counter-area">
                        <div className="counter-list mb-20">
                          <div className="counter-icon">
                            <img
                              src="assets/images/counter/icons/1.png"
                              alt="Counter"
                            />
                          </div>
                          <div className="counter-number">
                            <span className="rs-count">15</span>
                          </div>
                        </div>
                        <div className="content-part">
                          <h5 className="title">
                            Projects completed for our respected clients.
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 md-mb-30">
                      <div className="counter-area">
                        <div className="counter-list mb-20">
                          <div className="counter-icon">
                            <img
                              src="assets/images/counter/icons/2.png"
                              alt="Counter"
                            />
                          </div>
                          <div className="counter-number">
                            <span className="rs-count">18</span>
                            <span className="prefix">+</span>
                          </div>
                        </div>
                        <div className="content-part">
                          <h5 className="title">
                            Experienced people serving to clients.
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 xs-mb-30">
                      <div className="counter-area">
                        <div className="counter-list mb-20">
                          <div className="counter-icon">
                            <img
                              src="assets/images/counter/icons/3.png"
                              alt="Counter"
                            />
                          </div>
                          <div className="counter-number">
                            <span className="rs-count">11</span>
                            <span className="prefix">+</span>
                          </div>
                        </div>
                        <div className="content-part">
                          <h5 className="title">
                            Years experience in Cloud &amp; ERP.
                          </h5>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6 col-sm-6">
                                            <div className="counter-area">
                                                <div className="counter-list mb-20">
                                                    <div className="counter-icon">
                                                        <img src="assets/images/counter/icons/4.png" alt="Counter" />
                                                    </div>
                                                    <div className="counter-number">
                                                        <span className="rs-count">4</span>
                                                        <span className="prefix">+</span>
                                                    </div>
                                                </div>
                                                <div className="content-part">
                                                    <h5 className="title">Cloud &amp; ERP awards won over world.</h5>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* Counter Section End */}

            <div className="rs-counter style1">
              <div className="container">
                <div className="counter-border-top">
                  <div className="row ">
                    <div className="col-12 md-mb-30">
                      <div className="counter-area">
                        <div className="counter-list mb-20">
                          <div className="counter-icon">
                            <img
                              src="assets/images/counter/icons/1.png"
                              alt="Counter"
                            />
                          </div>
                          <div className="counter-number">
                            <span className="">
                              For additional information, kindly reach out to us
                              by clicking on the following link where you can
                              submit your inquiries. We appreciate your interest
                              and look forward to addressing any questions you
                              may have. Thank you.
                            </span>
                          </div>
                        </div>
                        <div className="content-part">
                          <h4>
                            <a
                              href="https://moe-suchierp.blogspot.com/"
                              target="_blank"
                            >
                              https://moe-suchierp.blogspot.com/
                            </a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Services Section End */}

          <div class="rs-faq faq-home-style5 pt-70 pb-30 md-pt-70 md-pb-30">
            <div class="container">
              <div class="sec-title3 text-center mb-60">
                {/* <div class="icon-part mb-25">
                                    <img src="assets/images/brand-logo.png" alt="" style={{width: '200px'}}/>
                                </div> */}
                <h2 class="title pb-20">
                  Why Choose{" "}
                  <img
                    src="assets/images/brand-logo.png"
                    alt=""
                    style={{ width: "150px" }}
                  />{" "}
                  for &amp; Your Business?
                </h2>
                <p class="dese margin-0">
                  SuchiERP combines the best of functionality, technology, and
                  intuition to collect, <br />
                  manage, and coordinate information for your business.
                </p>
              </div>
              <div class="row y-middle">
                <div class="col-lg-6 padding-0 md-mb-50">
                  <div class="rs-videos faq">
                    <div class="images-video"></div>
                    <div class="animate-border">
                      <a
                        class="popup-border"
                        href="https://www.youtube.com/watch?v=n_DrKix83yo"
                      >
                        <i class="fa fa-play"></i>
                        {/* <video width="320" height="240" controls>
                                                    <source src={Img7} type="video/mp4"/>
                                                </video> */}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 pl-55 md-pl-15">
                  <div class="content-part">
                    <div class="faq-content">
                      <div id="accordion" class="accordion">
                        <div class="card">
                          <div class="card-header">
                            <a
                              class="card-link collapsed"
                              href="/"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                            >
                              Cloud-based customer relationship management
                              software
                            </a>
                          </div>
                          <div
                            id="collapseFour"
                            class="collapse show"
                            data-bs-parent="#accordion"
                          >
                            <div class="card-body mt-30">
                              <ul class="icon-item">
                                <p>
                                  This helps businesses manage their customer
                                  data, sales, and marketing processes in one
                                  place.
                                </p>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header">
                            <a
                              class="card-link collapsed"
                              href="/"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="false"
                            >
                              Our CRM solution can help you keep track of your
                              customer interactions
                            </a>
                          </div>
                          <div
                            id="collapseFive"
                            class="collapse"
                            data-bs-parent="#accordion"
                          >
                            <div class="card-body mt-30">
                              <ul class="icon-item">
                                <p>
                                  Our CRM (customer relationship management)
                                  solution can help you keep track of all your
                                  customer interactions in one place. This
                                  includes phone calls, emails, live chats, and
                                  more. .
                                </p>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header">
                            <a
                              class="card-link collapsed"
                              href="/"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                            >
                              Marketing - Reach more customers and grow your
                              brand
                            </a>
                          </div>
                          <div
                            id="collapseSix"
                            class="collapse"
                            data-bs-parent="#accordion"
                          >
                            <div class="card-body mt-30">
                              <ul class="icon-item">
                                <p>
                                  With SuchiERP Cloud CRM, you can easily reach
                                  more customers and grow your brand. Our
                                  powerful marketing tools let you automate your
                                  marketing campaigns and track their
                                  performance, so you can see what's working and
                                  adjust your strategy on the fly. .
                                </p>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header">
                            <a
                              class="card-link collapsed"
                              href="/"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="false"
                            >
                              End-to-End Solutions
                            </a>
                          </div>
                          <div
                            id="collapseOne"
                            class="collapse"
                            data-bs-parent="#accordion"
                          >
                            <div class="card-body mt-30">
                              <ul class="icon-item">
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    No IT management hassles and minimized IT
                                    infrastructure costs
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Can be accessed anywhere, anytime
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Packed as a comprehensive ERP suite
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Offers complete insights through dashboards
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header">
                            <a
                              class="card-link collapsed"
                              href="/"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                            >
                              Extraordinary Functionality
                            </a>
                          </div>
                          <div
                            id="collapseTwo"
                            class="collapse"
                            data-bs-parent="#accordion"
                          >
                            <div class="card-body mt-30">
                              <ul class="icon-item">
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Cloud deployment ensuring seamless and
                                    hassle-free operations
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Scalability of users and resources for
                                    changing needs
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Easy integration of the tools and systems
                                    you already use
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Remote and multi-device accessibility
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Intuitive user interface for improved
                                    efficiency
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Customized solutions for varied industry
                                    requirements{" "}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header">
                            <a
                              class="card-link collapsed"
                              href="/"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                            >
                              Well-Received by Customers
                            </a>
                          </div>
                          <div
                            id="collapseThree"
                            class="collapse"
                            data-bs-parent="#accordion"
                          >
                            <div class="card-body mt-30">
                              <ul class="icon-item">
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Appropriate solution for small and
                                    medium-sized businesses
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Cost-effective solutions for small and
                                    medium businesses
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    Happy customers around the globe
                                  </span>
                                </li>
                                <li>
                                  <span class="list-icon">
                                    <i class="fa fa-check"></i>
                                  </span>
                                  <span class="list-text">
                                    24*7 customer support
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* About Section Start */}
          <div className="rs-about gray-bg2 style1 pt-30 pb-30 md-pt-30 md-pb-30">
            <div className="container">
              <div className="row y-middle">
                <div className="col-lg-12 pr-70 md-pr-15 md-mb-50">
                  <div className="sec-title2 mb-30">
                    <div className="sub-text">Who We Are</div>
                    <h2 className="title mb-23">
                      Who will win out, SuchiERP or the other ERPs?
                    </h2>
                    <p className="desc mb-0">
                      Determine your own. Utilize our most current upgrade to
                      the ERP Evaluation Checklist to rank your preferred
                      choices from 45 potential advantages. You will be
                      completely informed on what you should do next.{" "}
                    </p>
                  </div>
                  {/* Skillbar Section Start */}
                  <div className="rs-skillbar style1">
                    <ul class="icon-item">
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          When creating SuchiERP, the client was prioritized
                          over shareholders.
                        </span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          Highest levels of industry-wide customer satisfaction
                        </span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          Various deployment choices, including both public and
                          private clouds
                        </span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          Modern pay-as-you-go license based on consumption that
                          can be expanded or contracted as needed.
                        </span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          ERP implementations without added costs
                        </span>
                      </li>
                    </ul>
                    <div className="col-lg-12 mt-45">
                      <div className="btn-part">
                        <Link
                          className="readon consultant discover"
                          to="/contact-us"
                        >
                          Discover More
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* Skillbar Section End */}
                </div>
                {/* <div className="col-lg-4">
                                    <div className="about-img">
                                        <img src={Img38} alt="images" style={{width: '80%'}}/>                                      
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
          {/* About Section Start */}

          <div class="rs-about main-home pt-70 pb-70 md-pt-70 md-pb-70">
            <div class="container">
              <div class="row">
                <h1 class="pb-10 hidden-lg hidden-md hidden-sm visible-xs text-center">
                  Suchita Memorial Scholarship
                </h1>
                <div class="col-lg-6 md-mb-50">
                  <div class="images-part">
                    <Link to="/contact-us">
                      <img src={Img42} alt="Images" className="w-100" />
                    </Link>
                  </div>
                  <p className="mt-40">
                    The purpose of this scholarship is to{" "}
                    <b className="text-danger">
                      provide financial support to underprivileged students who
                      may not otherwise have the means to attend college.
                    </b>{" "}
                    It also encourages students to engage in volunteer work,
                    particularly with special needs individuals, which can be a
                    rewarding and fulfilling experience.
                  </p>
                </div>
                <div class="col-lg-6 pl-46 md-pl-15">
                  <div class="sec-title3">
                    <h1 class="pb-10 hidden-xs">
                      Suchita Memorial Scholarship
                    </h1>
                    <p class="margin-0 pb-10">
                      The Suchita Memorial Scholarship is a scholarship program
                      that provides financial assistance to underprivileged high
                      school students to help them pursue their first year of
                      college.
                    </p>
                    <p class="margin-0 pb-10">
                      To be eligible for this scholarship, students must
                      maintain grades higher than 3.3. Those who meet this
                      requirement will be given financial assistance to cover
                      the fees for their second year of college.
                    </p>

                    <p class="margin-0 pb-10">
                      In addition to academic qualifications, students must also
                      have volunteered with special needs students at their
                      school to be eligible for this scholarship. This
                      requirement is in place to honor the memory of Suchita,
                      the person for whom the scholarship is named.
                    </p>
                    <p class="margin-0 pb-10">
                      Overall, the Suchita Memorial Scholarship is an important
                      program that helps to provide underprivileged high school
                      students with the opportunity to pursue higher education.
                    </p>
                    <ul class="icon-item">
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">Grades higher than 3.3.</span>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="fa fa-check"></i>
                        </span>
                        <span class="list-text">
                          Students must also have volunteered with special needs
                          students
                        </span>
                      </li>
                    </ul>
                    <div class="btn-part mt-42">
                      <Link
                        to="/Overview"
                        class="readon consultant paste-btn more"
                      >
                        Know More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Services Section Start */}
          <div className="rs-services style2 rs-services-style2 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
            <div className="container custom">
              <h2 className="title pb-20">Our Valued Clients</h2>
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-20">
                  <div className="service-wrap">
                    <div className="image-part">
                      <img src={Img43} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-20">
                  <div className="service-wrap">
                    <div className="image-part">
                      <img src={Img44} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-20">
                  <div className="service-wrap">
                    <div className="image-part">
                      <img src={Img45} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Services Section End */}

          <div className="rs-about main-home pt-70 pb-70 md-pt-70 md-pb-70">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <h1 className="d-flex justify-content-center pb-10 text-center">
                  Certified
                </h1>
                <div
                  data-iframe-width="300"
                  data-iframe-height="270"
                  data-share-badge-id="54a06c73-cfd4-49f0-b6eb-836fa27ca547"
                  data-share-badge-host="https://www.credly.com"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
