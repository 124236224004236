import React from "react";

export default function DataScience() {
  return (
    <div>
      {/* Breadcrumbs Start */}
      {/* <div className="rs-breadcrumbs img14 aws-banner">
                <div className="container">
                    <div className="breadcrumbs-inner">
                        <h1 className="page-title">
                            What is <b className='' style={{fontSize: '6rem', color: '#ffe941'}}>Data Science?</b>
                            <span className="watermark"></span>
                        </h1>
                        <span className="sub-text">Data science is one of the most in-demand occupations of the decade, with a need for data scientists who can analyse data and convey findings to guide data-driven choices at an all-time high. 
                        </span>                        
                    </div>
                </div>
            </div> */}
      {/* Breadcrumbs End */}

      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-30">Learning Outcomes?</h2>
                <p className="margin-0 pb-40">
                  Data Science professional certificates can assist you in
                  becoming work-ready, whether you're trying to start a new
                  profession or change your existing one.{" "}
                </p>
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Show knowledge in the data science sector to impress
                      interviewers.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      {" "}
                      Understand the mathematics underpinning Machine Learning
                      (an important requirement that other courses do not
                      cover!) Learn how to pre-process data.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Learn how to code in Python and utilise it for statistical
                      analysis.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Be able to use NumPy, statsmodels, and scikit-learn to
                      develop Machine Learning algorithms in Python.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      In Python, run linear and logistic regressions, as well as
                      cluster and factor analysis.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12 md-mb-50 mt-30">
              <div className="images-part">
                <img
                  src="assets/images/pages/data-science/2.png"
                  alt="Images"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section End */}
      <div className="rs-services style3 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">Adoption of DATA SCIENCE in Finance!</h2>
            <p className="mt-20">
              The overall adoption of Analytics & Data Science at large Indian
              firms is around 74.5%. In other words,{" "}
              <b className="text-danger" style={{ fontSize: "4rem" }}>
                74.5%
              </b>{" "}
              of large firms in India have analytics penetration greater than
              1.0% in 2021. This is higher than the adoption rate in 2019 when
              it was at 70%.
            </p>
            <img
              src="assets/images/pages/data-science/3.png"
              alt="Services"
              className="mt-30 w-100"
            />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 md-mb-50">
              <div className="services-item">
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">
                      Data Science professional certificates can assist you in
                      becoming work-ready, whether you're trying to start a new
                      profession or change your existing one. You may learn at
                      your own speed, anytime and whenever suits you best. Enrol
                      today to begin your journey along a new professional path.
                      At any moment, you can suspend your studies or cancel your
                      membership.
                    </a>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50">
              <div className="services-item">
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">
                      The emphasis on applied learning is considerable in this
                      Professional Certificate. All subsequent courses, except
                      for the first, involve a series of hands-on labs on the
                      Cloud that will provide you with practical skills that can
                      apply to real-world situations.
                    </a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
      <div className="rs-services style3 pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">How will Data Science change the world?</h2>
            <div className="mt-30 mb-30">
              <img
                className="hovers-logos rs-grid-img w-50"
                src="assets/images/pages/data-science/4.png"
                alt=""
              />
            </div>
            <div className="mt-30 mb-30">
              <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/_fjUaDj77NI"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {/* Partner Section Start */}
      <div className="rs-patter-section black-bg pt-80 pb-75">
        <div className="container custom">
          <div
            className="rs-carousel owl-carousel"
            data-loop="true"
            data-items={5}
            data-margin={30}
            data-autoplay="true"
            data-hoverpause="true"
            data-autoplay-timeout={5000}
            data-smart-speed={800}
            data-dots="false"
            data-nav="false"
            data-nav-speed="false"
            data-md-device={5}
            data-md-device-nav="false"
            data-md-device-dots="false"
            data-center-mode="false"
            data-ipad-device2={4}
            data-ipad-device-nav2="false"
            data-ipad-device-dots2="false"
            data-ipad-device={4}
            data-ipad-device-nav="false"
            data-ipad-device-dots="false"
            data-mobile-device={2}
            data-mobile-device-nav="false"
            data-mobile-device-dots="false"
          >
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/1.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/1.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/2.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/2.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/3.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/3.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/4.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/4.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/5.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/5.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Partner Section End */}
    </div>
  );
}
