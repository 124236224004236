import React from "react";
import Img30 from "../../../assets/images/30.svg";

export default function ProjectAccounting() {  
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle mt-30">
            <div className="col-lg-6 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-20">Project accounting deals with?</h2>
                <p className="mt-10">
                  All the elements involved in the financial transactions of a
                  project. This includes everything from project costs,
                  payments, and revenue. Project managers and accountants use
                  project accounting when performing financial tasks for
                  projects. The Board of Directors receives regular reports on
                  its progress and the success or failure of project accounting.
                </p>
                <p className="mt-10">
                  The use of project accounting is essential in managing project
                  budgets; Project managers use it to track direct project
                  costs, overheads, and revenue. Just as a project manager
                  monitors the progress and scope of a project, they also track
                  these financial transactions to ensure they stay within budget
                  and make necessary adjustments to avoid overspending.
                </p>

                <h2 className="title pb-30 pt-30">
                  {" "}
                  <b>
                    Here are some key benefits to consider if you're unsure
                    whether your business can benefit from project accounting
                  </b>
                </h2>

                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Provides important information for project managers
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Allows you to set a reasonable budget for a project
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Improved resource management and Helps you gather valuable
                      information to bid on future projects
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Provide real-time updates on project progress and
                      profitability
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Allows management to identify project issues and make
                      quick decisions to get back on track and reduce costs
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Help educate project teams on how each factor can impact
                      project costs and profits
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Provide customers with accurate data for invoicing and
                      project progress tracking
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Helps you determine scope and pricing for similar projects
                      by analyzing actual versus estimated costs
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Improve the overall financial management of your business
                      and The bottom line here is that project accounting helps
                      you reduce the risk of project failure by improving
                      overall project management. .
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 md-mb-30 mt-30">
              <div className="images-part">
                <img src={Img30} alt="Images" className="w-100 " />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="rs-about"
        className="rs-about main-home pt-10 pb-30 md-pt-10 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle mt-30">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-20">
                  PRINCIPLES OF PROJECT MANAGEMENT
                </h2>

                <p className="mt-0">
                  Separate from financial accounting processes, Perform budget
                  forecasting before starting a project., Identify KPIs and Run
                  reports frequently and Establish cost codes. <br />
                  <b>
                    <i>
                      “Profitability depends on accurate costing Accurate
                      costing for raw material, work-in-progress(WIP) and
                      sub-assemblies, finished goods, or project cost should
                      always be part of the SuchiERP equation.”
                    </i>
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
