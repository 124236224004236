import React from "react";
import { Link } from "react-router-dom";

export default function Careers() {
  return (
    <div>
      {/* Process Section Start */}
      <div className="rs-process style2 pt-30 pb-50 md-pt-30 md-pb-50">
        <div className="container custom">
          <div className="sec-title3">
            <h2 className="title pb-20">Why choose SuchiERP?</h2>
            <p className="mt-10">
              SuchiERP offers the best functionality, technology, and intuition
              to collect, manage, and coordinate information for your business.
              With cloud deployment, easy integration of the tools and systems
              you already use, and a customized solution for your business,
              SuchiERP is the obvious choice for your business needs.
            </p>
            <p className="mt-10">
              In addition, SuchiERP is a comprehensive ERP suite packed with
              features to help businesses from different industries go sky-high.
              With 24*7 customer support, SuchiERP is always there to help you
              overcome any challenges you may face.
            </p>
            <div className="d-flex justify-content-center mt-30 box-content">
              <div className="sec-title3 d-flex justify-content-center flex-column">
                <h2 className="title pb-10 text-center">
                  {" "}
                  SuchiERP offers freelance work and remote work opportunities
                  for Cloud Deployment on regular basis. We always encourage
                  work from home and flexible hours.
                </h2>
              </div>
            </div>
            {/* <div className="images-part">
                  <img src={Img11} alt="Images" className="w-100 " />
                </div> */}
          </div>

          <div className="row y-middle mb-50">
            <div className="col-lg-5">
              <div className="sec-title md-mb-30">
                <h2 className="title">Job Openings at SuchiERP</h2>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-lg-4 col-md-6 mb-30">
              <div className="rs-addon-number">
                <div className="number-part">                 
                  <div className="number-title">
                    <h3 className="title">Project Manager (ERP)</h3>
                  </div>
                  <div className="number-txt">
                    Looking for Project Managers.
                    <div className="loac-text">
                      <strong>Vacancies:</strong> 1
                    </div>
                    <div className="loac-text">
                      <strong>Location:</strong> 6701 Highway Blvd #211, Katy
                      Texas 77494
                    </div>
                  </div>
                  <div className="btn-part">
                    <Link to="/contact-us" className="readon apply">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <div className="rs-addon-number">
                <div className="number-part">                 
                  <div className="number-title">
                    <h3 className="title">Project Manager (IAM)</h3>
                  </div>
                  <div className="number-txt">
                    Looking for Project Managers.
                    <div className="loac-text">
                      <strong>Vacancies:</strong> 1
                    </div>
                    <div className="loac-text">
                      <strong>Location:</strong> 6701 Highway Blvd #211, Katy
                      Texas 77494
                    </div>
                  </div>
                  <div className="btn-part">
                    <Link to="/contact-us" className="readon apply">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <div className="rs-addon-number">
                <div className="number-part">                 
                  <div className="number-title">
                    <h3 className="title">Java Developer</h3>
                  </div>
                  <div className="number-txt">
                    We are looking for who has very good experience on Java.
                    <div className="loac-text">
                      <strong>Location:</strong> 6701 Highway Blvd #211, Katy
                      Texas 77494
                    </div>
                  </div>
                  <div className="btn-part">
                    <Link to="/contact-us" className="readon apply">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <div className="rs-addon-number">
                <div className="number-part">
                  <div className="number-title">
                    <h3 className="title">SpringBoot Developer</h3>
                  </div>
                  <div className="number-txt">
                    We are looking for who has very good experience on
                    SpringBoot.
                    <div className="loac-text">
                      <strong>Location:</strong> 6701 Highway Blvd #211, Katy
                      Texas 77494
                    </div>
                  </div>
                  <div className="btn-part">
                    <Link to="/contact-us" className="readon apply">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <div className="rs-addon-number">
                <div className="number-part">
                  <div className="number-title">
                    <h3 className="title">DevOps Engineer</h3>
                  </div>
                  <div className="number-txt">
                    We are looking for who has 2+ years of experience on DevOps
                    Engineer.
                    <div className="loac-text">
                      <strong>Location:</strong> 6701 Highway Blvd #211, Katy
                      Texas 77494
                    </div>
                  </div>
                  <div className="btn-part">
                    <a href="/" className="readon apply">Apply Now</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 md-mb-30">
              <div className="rs-addon-number">
                <div className="number-part">
                  <div className="number-title">
                    <h3 className="title">MySQL Reporting</h3>
                  </div>
                  <div className="number-txt">
                    Job openings for who has well and good experience on MySQL
                    Reporting
                    <div className="loac-text">
                      <strong>Location:</strong> 6701 Highway Blvd #211, Katy
                      Texas 77494
                    </div>
                  </div>
                  <div className="btn-part">
                    <Link to="/contact-us" className="readon apply">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sm-mb-30">
              <div className="rs-addon-number">
                <div className="number-part">
                  <div className="number-title">
                    <h3 className="title">Angular 7/8/9 Developer</h3>
                  </div>
                  <div className="number-txt">
                    We are looking for who has very good experience on
                    AngularJS/versions.
                    <div className="loac-text">
                      <strong>Location:</strong> 6701 Highway Blvd #211, Katy
                      Texas 77494
                    </div>
                  </div>
                  <div className="btn-part">
                    <Link to="/contact-us" className="readon apply">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="rs-addon-number">
                <div className="number-part">
                  <div className="number-title">
                    <h3 className="title">Full Stack Developer</h3>
                  </div>
                  <div className="number-txt">
                    We are looking for who has very good experience on Hadoop
                    Technology.
                    <div className="loac-text">
                      <strong>Location:</strong> 6701 Highway Blvd #211, Katy
                      Texas 77494
                    </div>
                  </div>
                  <div className="btn-part">
                    <Link to="/contact-us" className="readon apply">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Process Section End */}
    </div>
  );
}
